import cx from 'classnames';
import { ChipStyles, Color, Size } from './Chip.types';

export const getChipStyles = (color: Color, size: Size): ChipStyles => {
  const chipColoring: ChipStyles = (() => {
    switch (color) {
      case Color.PRIMARY:
        return {
          wrapperStyles: 'bg-primary-600-opacity-5 hover:bg-primary-600-opacity-10 active:bg-primary-600-opacity-20',
          iconStyles: 'text-primary-600',
          labelStyles: 'text-primary-600',
          subLabelStyles: 'bg-primary-700 text-neutral-50 group-hover:bg-primary-800 group-active:bg-primary-800',
          removeButtonStyles: 'text-primary-600',
        };
      case Color.PURPLE:
        return {
          wrapperStyles: 'bg-purple-600-opacity-5 hover:bg-purple-600-opacity-10 active:bg-purple-600-opacity-20',
          iconStyles: 'text-purple-700',
          labelStyles: 'text-purple-700',
          subLabelStyles: 'bg-purple-700 text-neutral-50',
          removeButtonStyles: 'text-purple-700',
        };
      case Color.PINK_RED:
        return {
          wrapperStyles: 'bg-pinkRed-600-opacity-5 hover:bg-pinkRed-600-opacity-10 active:bg-pinkRed-600-opacity-20',
          iconStyles: 'text-pinkRed-600',
          labelStyles: 'text-pinkRed-600',
          subLabelStyles: 'bg-pinkRed-700 text-neutral-50',
          removeButtonStyles: 'text-pinkRed-600',
        };
      case Color.YELLOW:
        return {
          wrapperStyles: 'bg-yellow-600-opacity-5 hover:bg-yellow-600-opacity-10 active:bg-yellow-600-opacity-20',
          iconStyles: 'text-yellow-600',
          labelStyles: 'text-yellow-600',
          subLabelStyles: 'bg-yellow-700 text-neutral-50',
          removeButtonStyles: 'text-yellow-600',
        };
      case Color.LIME:
        return {
          wrapperStyles: 'bg-lime-600-opacity-5 hover:bg-lime-600-opacity-10 active:bg-lime-600-opacity-20',
          iconStyles: 'text-lime-600',
          labelStyles: 'text-lime-600',
          subLabelStyles: 'bg-lime-700 text-neutral-50',
          removeButtonStyles: 'text-lime-600',
        };
      case Color.PINK:
        return {
          wrapperStyles: 'bg-pink-600-opacity-5 hover:bg-pink-600-opacity-10 active:bg-pink-600-opacity-20',
          iconStyles: 'text-pink-600',
          labelStyles: 'text-pink-600',
          subLabelStyles: 'bg-pink-700 text-neutral-50',
          removeButtonStyles: 'text-pink-600',
        };
      case Color.ORANGE:
        return {
          wrapperStyles: 'bg-orange-600-opacity-5 hover:bg-orange-600-opacity-10 active:bg-orange-600-opacity-20',
          iconStyles: 'text-orange-600',
          labelStyles: 'text-orange-600',
          subLabelStyles: 'bg-orange-700 text-neutral-50',
          removeButtonStyles: 'text-orange-600',
        };
      case Color.SKY_BLUE:
        return {
          wrapperStyles: 'bg-sky-500-opacity-5 hover:bg-sky-500-opacity-10 active:bg-sky-500-opacity-20',
          iconStyles: 'text-sky-500',
          labelStyles: 'text-sky-500',
          subLabelStyles: 'bg-sky-600 text-neutral-50',
          removeButtonStyles: 'text-sky-500',
        };
      case Color.RED:
        return {
          wrapperStyles: 'bg-pinkRed-600-opacity-5 hover:bg-pinkRed-600-opacity-10 active:bg-pinkRed-600-opacity-20',
          iconStyles: 'text-pinkRed-700',
          labelStyles: 'text-pinkRed-700',
          subLabelStyles: 'bg-pinkRed-800 text-neutral-50',
          removeButtonStyles: 'text-pinkRed-700',
        };
      case Color.GREEN:
        return {
          wrapperStyles: 'bg-green-500-opacity-5 hover:bg-green-500-opacity-10 active:bg-green-500-opacity-20',
          iconStyles: 'text-green-700',
          labelStyles: 'text-green-700',
          subLabelStyles: 'bg-green-700 text-neutral-50',
          removeButtonStyles: 'text-green-700',
        };
      case Color.DISABLED:
        return {
          wrapperStyles: 'bg-neutral-950-opacity-5',
          iconStyles: 'text-neutral-400',
          labelStyles: 'text-neutral-400',
          subLabelStyles: 'bg-neutral-300 text-neutral-400',
          removeButtonStyles: 'text-neutral-400 cursor-not-allowed',
        };
      default:
        return {
          wrapperStyles: 'bg-neutral-950-opacity-5 hover:bg-neutral-950-opacity-10 active:bg-neutral-950-opacity-20',
          iconStyles: 'text-neutral-600 group-hover:text-neutral-800 group-active:text-neutral-800',
          labelStyles: 'text-neutral-600 group-hover:text-neutral-800 group-active:text-neutral-800',
          subLabelStyles: 'bg-neutral-800 text-neutral-50',
          removeButtonStyles: 'text-neutral-600 group-hover:text-neutral-800 group-active:text-neutral-800',
        };
    }
  })();

  const chipSizing: ChipStyles = (() => {
    switch (size) {
      case Size.LARGE:
        return {
          wrapperStyles: 'rounded-md h-7',
          iconStyles: 'w-4 h-4 stroke-1',
          labelStyles: 'body-base',
          subLabelStyles: 'rounded-md body-base',
          removeButtonStyles: 'w-4 h-4 stroke-1',
        };
      case Size.SMALL:
        return {
          wrapperStyles: 'rounded-full h-5',
          iconStyles: 'w-3 h-3 stroke-1',
          labelStyles: 'body-sm',
          subLabelStyles: 'rounded-full body-sm',
          removeButtonStyles: 'w-3 h-3 stroke-1',
        };
      default:
        return {
          wrapperStyles: 'rounded-full h-6',
          iconStyles: 'w-4 h-4 stroke-1',
          labelStyles: 'body-base',
          subLabelStyles: 'rounded-full body-base',
          removeButtonStyles: 'w-4 h-4 stroke-1',
        };
    }
  })();

  return {
    wrapperStyles: cx(chipColoring.wrapperStyles, chipSizing.wrapperStyles),
    iconStyles: cx(chipColoring.iconStyles, chipSizing.iconStyles),
    labelStyles: cx(chipColoring.labelStyles, chipSizing.labelStyles),
    subLabelStyles: cx(chipColoring.subLabelStyles, chipSizing.subLabelStyles),
    removeButtonStyles: cx(chipColoring.removeButtonStyles, chipSizing.removeButtonStyles),
  };
};
