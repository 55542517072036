import { CancelFunctions } from 'components/common/types';

export interface DealIdType {
  dealName: string;
  dealId: string;
}

export interface FilterProps {
  onChange: (value: DealIdType) => void;
  filterByDealId: string;
  cancelFunctions: CancelFunctions;
}

export enum PagesStatusCode {
  LIVE = 'LIVE',
  ENDED = 'ENDED',
}

export enum PagesDataOptions {
  CURRENT_PAGE = 'currentPage',
  IS_LAST = 'isLast',
}

export interface FilteredDealData {
  dealId: string;
  dealName: string;
  pagesStatusCode: PagesStatusCode;
}

export interface PageParams {
  [PagesDataOptions.CURRENT_PAGE]: number;
  [PagesDataOptions.IS_LAST]: boolean;
}

export type PageParamsByStatus = Record<PagesStatusCode, PageParams>;

export type DealIdsDataByStatusCode = Record<PagesStatusCode, DealIdType[]>;
