import BaseSelect from 'lib/BaseSelect';
import { Label } from 'lib/Label';
import { UseAllocationReport } from '../useAllocationReport';
import { metricOptions } from '../consts';
import { MetricOption } from '../AllocationReport.types';

interface DropdownMetricProps
  extends Pick<ReturnType<UseAllocationReport>, 'handleChangeMetricType' | 'selectedMetricType'> {}

export const DropdownMetric: React.FC<DropdownMetricProps> = ({ handleChangeMetricType, selectedMetricType }) => {
  return (
    <div className="flex flex-col min-w-36" data-dd-action-name="Allocation report / Metric dropdown">
      <Label label="Metrics" />
      <BaseSelect
        dataTestId="metrics"
        name="Metrics"
        options={metricOptions}
        selectedValue={selectedMetricType}
        onSelect={(_, value: MetricOption) => handleChangeMetricType(value)}
      />
    </div>
  );
};
