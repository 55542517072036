import { CodeNameModel, FileList } from '.';
import { CommonDeal, Line, GroupOfSelectedDays } from './Deal.types';

export enum DirectSalesCampaignType {
  STANDARD = 'STANDARD',
}

export enum DirectSalesFilterRelationship {
  AND = 'AND',
  OR = 'OR',
}

export enum DirectSalesFilterGroup {
  LOCATION = 'LOCATION',
  TAG = 'TAG',
  LIST_TARGET = 'LIST_TARGET',
}

export enum DirectSalesSubOptionTypes {
  LOCATION = 'location',
  FORMAT = 'format',
}

export enum DirectSalesFilterGroupCriteria {
  TAG = 'TAG',
  VOLUME_TARGET = 'VOLUME_TARGET',
  BUDGET_TARGET = 'BUDGET_TARGET',
  FRAME_TARGET = 'FRAME_TARGET',
  SOT_TARGET = 'SOT_TARGET',
  FRAME_LIST = 'FRAME_LIST',
}

export enum DirectSalesFilterScope {
  INCLUDE = 'INCLUDE',
  EXCLUDE = 'EXCLUDE',
}

enum DirectSalesDuplicationExclusion {
  ON = 'ON',
  OFF = 'OFF',
}

export enum DirectSalesMediaTypeLabel {
  DIGITAL = 'Digital',
  PAPER = 'Paper',
}

export enum DirectSalesMediaType {
  DIGITAL = 'DIGITAL',
  PAPER = 'PAPER',
}

enum DirectSalesAllocationSolutionStatus {
  GREEN = 'GREEN',
}

export type DirectSales = {
  dealLineFormats: DirectSalesCodeNameModel[];
  locations: DirectSalesCodeNameModel[];
  mediaType?: DirectSalesMediaType;
  state: DirectSalesState;
  lockState: DirectSalesLockState;
};

export type DirectSalesLine = Line & DirectSales;

export interface DirectSalesCampaign extends CommonDeal, DirectSales {
  sessionState: DirectSalesLockState;
}

export interface DirectSalesCodeNameModel extends Pick<CodeNameModel, 'code' | 'name' | 'include'> {
  status?: DirectSalesFilterScope;
  category?: string;
}

export type DirectSalesSubOptions = {
  [k in DirectSalesSubOptionTypes]?: CodeNameModel[];
};

export interface DirectSalesOption extends DirectSalesSubOptions {
  code: string;
  name: string;
}

export type DirectSalesCampaignHeader = {
  id: string;
  publisher: string;
  version: string;
  salesPerson?: CodeNameModel;
  salesTeam?: CodeNameModel;
  adminPerson?: CodeNameModel;
  sessionState: DirectSalesLockState;
};

export type DirectSalesCampaignDealLevelDefinition = {
  campaignGroup: DirectSalesCampaignType;
  campaignType: DirectSalesCampaignType;
  advertiserId: string;
  brandId: string;
  productCategory: string;
  agencyId: string;
  specialistId: string;
  salesPerson?: CodeNameModel | null;
  salesTeam?: CodeNameModel | null;
  adminPerson?: CodeNameModel | null;
  name?: string;
};

type DirectSalesCampaignDefinitionEnrichmentResponse = {
  advertiserName: string;
  brandName: string;
  productCategoryName: string;
  agencyName?: string;
  specialistName?: string;
  name?: string;
};

export type DirectSalesCampaignDefinitionRequest = DirectSalesCampaignDealLevelDefinition &
  DirectSalesCampaignMultiDealLineRequest & { duplicationExclusion?: DirectSalesDuplicationExclusion };

export type DirectSalesCampaignDefinitionResponse = Omit<DirectSalesCampaignDefinitionRequest, 'dealLines'> &
  DirectSalesCampaignDefinitionEnrichmentResponse & {
    dealLines: DirectSalesCampaignDealLineResponse[];
  };

export type DirectSalesAllocationSolutionInformation = {
  allocationSolutionUUID: string;
  measures: {
    campaignMeasures: DirectSalesCampaignMeasure[];
    audienceMeasures: DirectSalesAudienceMeasure[];
    nbUnits: number;
    status: DirectSalesAllocationSolutionStatus;
    nbGreenObjectives: number;
    nbOrangeObjectives: number;
    nbRedObjectives: number;
  };
};

type DirectSalesPricingSolutionTotals = {
  [key: string]: string;
};

export type DirectSalesPricingSolution = {
  allocationSolutionUUID: string;
  pricingSetVersion: string;
  totalCampaignPrice: string;
  dealLineTotals: DirectSalesPricingSolutionTotals;
  pricingTypeTotals: DirectSalesPricingSolutionTotals;
};

type DirectSalesAudienceMeasure = {
  contacts: number;
  group: number;
  key: string;
};

type DirectSalesCampaignMeasure = {
  campaignId: string;
  bookingMeasures: DirectSalesBookingMeasure[];
};

type DirectSalesBookingMeasure = {
  bookingId: string;
  objectiveMeasures: DirectSalesBookingObjectiveMeasure[];
};

type DirectSalesBookingObjectiveMeasure = {
  objectiveId: string;
  type: string;
  value: number;
};

type DirectSalesCampaignDealLineRange = {
  startDate: string;
  endDate: string;
};

type DirectSalesFilterCriteriaId = {
  uuid: string;
};

export type DirectSalesFilterCriteria = {
  group: DirectSalesFilterGroup;
  criteriaType: DirectSalesFilterGroupCriteria;
  scope: DirectSalesFilterScope;
  values: DirectSalesFilterCriteriaId[];
  type: string;
};

type DirectSalesTargetCriteriaValue = {
  target: number;
  greenTolerance?: number;
  orangeTolerance?: number;
  boostedTarget?: boolean;
  priority?: number;
  weight?: number;
};

export type DirectSalesTargetCriteria = {
  value: DirectSalesTargetCriteriaValue;
  criteriaType: DirectSalesFilterGroupCriteria;
  type?: string;
};

export enum DirectSalesDaypartDurationType {
  HOUR = 'HOUR',
  DAYPART = 'DAYPART',
  DAY = 'DAY',
}

export enum DirectSalesStatusOption {
  DRAFT_UPDATING = 'DRAFT_UPDATING',
  DRAFT_SOLVED = 'DRAFT_SOLVED',
  DRAFT_FAILED_SOLVE = 'DRAFT_FAILED_SOLVE',
  DRAFT_PRICED = 'DRAFT_PRICED',
  DRAFT_FAILED_PRICE = 'DRAFT_FAILED_PRICE',
  DRAFT_SOLVING_OPTION_RESERVATION = 'DRAFT_SOLVING_OPTION_RESERVATION',
  DRAFT_SOLVING_CONFIRMED_RESERVATION = 'DRAFT_SOLVING_CONFIRMED_RESERVATION',
  OPTION = 'OPTION',
  PROPOSAL = 'PROPOSAL',
  CONFIRMED = 'CONFIRMED',
  CANCELLED = 'CANCELLED',
  DELETED = 'DELETED',
}

export const DirectSalesStatusOptionEvent: Record<string, string> = {
  [DirectSalesStatusOption.OPTION]: 'TO_OPTION',
  [DirectSalesStatusOption.PROPOSAL]: 'TO_PROPOSAL',
  [DirectSalesStatusOption.CONFIRMED]: 'CONFIRM',
  [DirectSalesStatusOption.CANCELLED]: 'CANCEL',
  [DirectSalesStatusOption.DELETED]: 'DELETE',
};

export enum DirectSalesLockStatusOption {
  LOCKED = 'LOCKED',
  UNLOCKED = 'UNLOCKED',
}

export enum DirectSalesStatusAction {
  SAVE_AS = 'Save as',
  UPDATE = 'Update',
  REVERT_TO = 'Revert to',
  CANCEL = 'Cancel',
}

export type DirectSalesStatusLabel = {
  label: string;
  value: DirectSalesStatusOption;
};

export interface DirectSalesState {
  status: DirectSalesStatusOption;
  expires?: Date | string;
}

export interface DirectSalesLockState {
  status: DirectSalesLockStatusOption;
  expires?: Date | string;
}

type DirectSalesCampaignDealLineDaypartHourRange = { hours: number[] };

export type DirectSalesCampaignDealLineDaypartDuration = {
  type: string;
  range: DirectSalesCampaignDealLineDaypartHourRange[] | [];
};

export type DirectSalesCampaignDealLineDaypartPattern = {
  week: number;
  days: string[];
  durations: DirectSalesCampaignDealLineDaypartDuration[];
};

export type DirectSalesCampaignDealLineRangePattern = {
  length: number;
  patterns: DirectSalesCampaignDealLineDaypartPattern[];
};

type CodeName = Pick<CodeNameModel, 'code' | 'name'>;

export type DirectSalesChannel = CodeName & {
  audienceCategoryGroupCode: string;
  dayPartGroupCode: string;
};

export type DirectSalesCampaignDealLineResponse = {
  id?: string;
  name: string;
  state: DirectSalesState;
  lockState: DirectSalesLockState;
  range: DirectSalesCampaignDealLineRange;
  rangePattern?: DirectSalesCampaignDealLineRangePattern;
  channelIds: DirectSalesChannel[];
  mediaType?: DirectSalesMediaType;
  filterCriteria?: DirectSalesFilterCriteria[];
  targetCriteria: DirectSalesTargetCriteria[];
  creationTime?: string;
  filter?: DirectSalesFilter;
};

export type DirectSalesTransformedDealLineRequest = Omit<
  DirectSalesCampaignDealLineResponse,
  'channelIds' | 'state' | 'lockState'
> & {
  channelIds: string[];
};

export enum DirectSalesFilterTypes {
  AndFilter = 'AndFilter',
  OrFilter = 'OrFilter',
  NotFilter = 'NotFilter',
}

export type DirectSalesNestedFilter = {
  type: DirectSalesFilterTypes;
  filter: {
    type: DirectSalesFilterTypes;
    filters: DirectSalesFilterItem[];
  };
};

export type DirectSalesFilterItem = {
  type: string;
  id: string;
  name: string;
};

export type DirectSalesFilter = {
  type: DirectSalesFilterTypes;
  filters: (DirectSalesFilterItem | DirectSalesNestedFilter)[];
};

export type DirectSalesCampaignDealLineRequest = {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  filter?: DirectSalesFilter;
  filterCriteria: DirectSalesFilterCriteria[];
  channelIds: DirectSalesChannel[];
  impressions?: number;
  budget?: number;
  frames?: number;
  sot?: number;
  mediaType?: DirectSalesMediaType;
  selectedDays?: GroupOfSelectedDays;
  patternLength?: number;
  listFiles?: FileList[];
};

export type DirectSalesCampaignMultiDealLineRequest = {
  dealLines: DirectSalesTransformedDealLineRequest[];
};

export type DirectSalesLinesTransformArgs = {
  lines: Partial<Line>[];
  directSales: DirectSales;
};
