import { DealStatus } from '../types/Deal.types';

export enum ReportType {
  FINAL = 'FINAL',
  PROPOSAL = 'PROPOSAL',
}

export interface SelectedLine {
  lineDefaultReportType: ReportType;
  lineId: string;
  lineName: string;
  lineStatus?: DealStatus;
}

export interface AllocationReportProps {
  defaultReportType?: ReportType;
  onClose: () => void;
}

export enum MetricType {
  IMPRESSIONS = 'IMPRESSIONS',
  FRAMES = 'FRAMES',
  SOT = 'SOT',
}

export interface MetricOption {
  name: string;
  code: MetricType;
}

export type ValueFormatter = (value: number) => string;

export interface AllocationHour {
  allocationHour: number;
  metricValue: number;
}

export interface AllocationDay {
  date: string;
  hours: AllocationHour[];
  total: number;
}

export interface AllocationWeek {
  allocationWeek: number;
  days: AllocationDay[];
  total: number;
}

export interface Frame {
  allocations: AllocationWeek[];
  frameId: string;
  total: number;
}

interface MetaData {
  report: {
    id: string;
    version: number;
    creationDateTime: string;
  };
  deal: {
    id: string;
    startDateTime: string;
    endDateTime: string;
  };
}

export interface TotalByHour {
  allocationHour: number;
  metricValue: number;
}

export interface TotalByDay {
  date: string;
  hours: TotalByHour[];
  total: number;
}

export interface TotalByWeek {
  allocationWeek: number;
  days: TotalByDay[];
  total: number;
}

export interface AllocationReportData {
  frames: Frame[];
  endDate: string;
  lineDuration: number;
  metaData: MetaData;
  metricType: MetricType;
  startDate: string;
  total: number;
  totalAllocations: TotalByWeek[];
}
