import clockSvg from 'assets/icons/clock.svg';
import Chip from 'lib/Chip';
import { Size } from 'lib/Chip/Chip.types';
import { handleReportCreationDateTime } from './util';

interface ReportCreationDateChipProps {
  creationDateTime: string;
  timezone: string;
}

export const ReportCreationDateChip: React.FC<ReportCreationDateChipProps> = ({ creationDateTime, timezone }) => {
  const chipLabel = handleReportCreationDateTime(creationDateTime, timezone);

  return (
    <div className="ml-2">
      <Chip icon={clockSvg} label={chipLabel} size={Size.SMALL} />
    </div>
  );
};
