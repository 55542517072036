import { components } from 'react-select';
import Status from 'lib/Status';
import { PagesStatusCode } from '../Filter.types';
import { OptionProps } from './Option.types';

const Option: React.FC<OptionProps> = (props) => {
  const {
    data: { dealName, pagesStatusCode },
  } = props;
  const isLiveDeal = pagesStatusCode === PagesStatusCode.LIVE;
  const [id, name] = dealName.split(':');

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.Option {...props}>
      <div className="flex justify-between space-x-2 w-80">
        <div data-test-id="option-deal-info">
          <p className="body-base text-neutral-950 break-all" data-test-id="option-deal-info-name">
            {name}
          </p>
          <p className="body-base text-neutral-600" data-test-id="option-deal-info-id">
            {id}
          </p>
        </div>
        <div data-test-id="option-deal-info-status">
          <Status initial={isLiveDeal ? 'L' : 'E'} backgroundColour={isLiveDeal ? 'bg-sky' : 'bg-neutral-900'} />
        </div>
      </div>
    </components.Option>
  );
};

export default Option;
