import { Dispatch } from 'redux';
import { NavigateFunction } from 'react-router-dom';
import { CancelFunctions } from 'components/common/types';
import { getDefaultSweep } from 'utils/deal';
import { getDeal } from 'modules/api/adsDeal';
import { transformDealLineDetails } from 'components/pages/DealWithLines/AdsDealLines/transformDealDetails';
import { fetchDealBids, getDealLinesDetails } from 'modules/api/deal';
import { transformMultiLineDealDetails } from 'components/pages/DealWithLines/DealLines/transformDealDetails';

import {
  CommonDeal,
  DealPriority,
  DealType,
  Programmatic,
  ProgrammaticCampaign,
  SelectedDay,
} from 'components/common/types/Deal.types';
import { DealConfigStore } from 'store/dealConfig/dealConfig.types';
import {
  BackupFormData,
  changeDealCurrentLineData,
  changeDealData,
  changeDealDetails,
  changeOnlyProgrammaticDealData,
  clearForm,
} from './reducer';

export const changeDealType =
  (dealType: DealType, hasNonGuaranteedExtendedTargetEnabled: boolean) =>
  async (dispatch: Dispatch): Promise<void> => {
    const isGuaranteed = dealType === DealType.GUARANTEED;

    dispatch(
      changeDealCurrentLineData({
        sweep: getDefaultSweep(dealType, hasNonGuaranteedExtendedTargetEnabled),
      }),
    );

    dispatch(
      changeDealData({
        dealType,
        dealPriority: isGuaranteed ? DealPriority.PREFERRED : DealPriority.STANDARD,
      }),
    );
  };

export const changeDealInfo =
  (dealInfo: Partial<CommonDeal>) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(changeDealData(dealInfo));
  };

export const changeProgrammaticDealInfo =
  (dealInfo: Partial<ProgrammaticCampaign>) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(changeOnlyProgrammaticDealData(dealInfo));
  };

export const changeSelectedDays =
  (selectedDays: { [key: string]: SelectedDay }) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(changeDealCurrentLineData({ selectedDays }));
  };

export const getDealDetails =
  (
    internalId: string,
    cancelFunctions: CancelFunctions,
    navigate: NavigateFunction,
    config: DealConfigStore,
    hasAdsDealLevelCPMEnabled: boolean,
    hasNonGuaranteedExtendedTargetEnabled: boolean,
    isAdServerMarket: boolean,
  ) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      if (isAdServerMarket) {
        const dealDetails = await getDeal(internalId, cancelFunctions);

        try {
          const bids = await fetchDealBids(cancelFunctions, internalId);

          if (bids) {
            dealDetails.bids = bids;
          }
        } catch {} // eslint-disable-line no-empty

        dispatch(
          changeDealDetails({
            ...(transformDealLineDetails({
              ...dealDetails,
              hasNonGuaranteedExtendedTargetEnabled,
            }) as {
              backupFormData: BackupFormData;
              commonDeal: Partial<CommonDeal>;
              programmatic: Programmatic;
            }),
            hasAdsDealLevelCPMEnabled,
            isAdServerMarket,
          }),
        );
      } else {
        const dealDetails = await getDealLinesDetails(internalId, cancelFunctions);

        try {
          const bids = await fetchDealBids(cancelFunctions, dealDetails.dealId);

          if (bids) {
            dealDetails.bids = bids;
          }
        } catch {} // eslint-disable-line no-empty

        dispatch(
          changeDealDetails({
            ...(transformMultiLineDealDetails(dealDetails, config, hasNonGuaranteedExtendedTargetEnabled) as {
              backupFormData: BackupFormData;
              commonDeal: Partial<CommonDeal>;
              programmatic: Programmatic;
              hasNonGuaranteedExtendedTargetEnabled: boolean;
            }),
            hasAdsDealLevelCPMEnabled,
            isAdServerMarket,
          }),
        );
      }
    } catch (error) {
      dispatch(clearForm());
      navigate('/deal');
    }
  };
