import BaseSelect from 'lib/BaseSelect';
import { FilterCreativeTypeProps } from './FilterCreativeType.types';

const FilterCreativeType: React.FC<FilterCreativeTypeProps> = ({ options, selectedValue, onSelect }) => {
  return (
    <BaseSelect
      dataTestId="creative-types"
      name="creativeType"
      options={options}
      selectedValue={selectedValue}
      onSelect={onSelect}
      placeholder="Select creative type"
    />
  );
};

export default FilterCreativeType;
