/* eslint-disable react/jsx-props-no-spreading */

import SVG from 'react-inlinesvg';
import cx from 'classnames';
import { Label } from 'lib/Label';
import { InputShape, InputSize, InputTheme, InputType } from 'components/common/types/Input.types';
import { InputAlign, BaseInputProps, InputBorderStyleProps, InputStyleProps } from './BaseInput.types';

import './BaseInput.pcss';

const InputSizeStyles = {
  LARGE: 'py-3',
  MEDIUM: 'py-2',
  SMALL: 'py-1',
};

const ShapeStyles = {
  SQUARE: 'rounded-none',
  ROUNDED: 'rounded-md',
  CIRCLE: 'rounded-full',
};

const InputStyle =
  'truncate flex w-full px-4 outline-none appearance-none body-base bg-transparent input-textfield disabled:cursor-not-allowed focus:outline-none focus:border-none';

const InputThemeStyles = {
  dark: 'font-normal text-sm text-essential-primary placeholder:text-neutral-600 disabled:placeholder:text-neutral-950-opacity-30 placeholder:font-normal disabled:text-neutral-950-opacity-30',
  light: cx(
    'body-base placeholder:body-base',
    'text-essential-primary disabled:text-neutral-950-opacity-30',
    'placeholder:text-neutral-600 disabled:placeholder:text-neutral-950-opacity-30',
  ),
};

export const getInputBorderStyle = ({
  errorMessage = '',
  classNames = '',
  shape = InputShape.ROUNDED,
  isDisabled = false,
}: InputBorderStyleProps = {}): string =>
  cx(
    'relative group w-full flex align-items-center border border-neutral-950-opacity-10 shadow-inner-sm',
    'focus-within:ring-2 focus-within:border-1 focus-within:outline-none',
    ShapeStyles[shape],
    classNames,
    {
      'hover:bg-neutral-100 focus-within:border-primary-600 focus-within:ring-primary-600':
        !errorMessage && !isDisabled,
      'border-pinkRed-200 ring-pinkRed-100 hover:bg-pinkRed-50 focus-within:border-pinkRed-200 focus-within:ring-pinkRed-400':
        errorMessage && !isDisabled,
      'bg-neutral-950-opacity-5 cursor-not-allowed': isDisabled && !errorMessage,
    },
  );

export const getInputStyle = ({
  size = InputSize.MEDIUM,
  theme = InputTheme.LIGHT,
  classNames = '',
  align = InputAlign.LEFT,
}: InputStyleProps = {}): string =>
  cx(InputStyle, InputSizeStyles[size], InputThemeStyles[theme], classNames, {
    'text-right': align === InputAlign.RIGHT,
  });

export const BaseInput: React.FC<BaseInputProps> = ({
  id,
  dataTestId,
  label,
  name,
  value,
  placeholder,
  onChange,
  onBlur,
  isDisabled,
  isRequired,
  infoMessage,
  errorMessage,
  textSymbol,
  startIconSvg,
  endIconSvg,
  endComponent,
  renderEndComponent,
  align = InputAlign.LEFT,
  type = InputType.TEXT,
  size = InputSize.MEDIUM,
  theme = InputTheme.LIGHT,
  ...htmlInputProps
}) => {
  return (
    <div className="relative">
      {label && <Label id={id} label={label} isRequired={isRequired} />}
      <div
        className={getInputBorderStyle({
          isDisabled,
          errorMessage,
        })}
      >
        {startIconSvg && (
          <div data-test-id={`${id}-icon`}>
            <SVG src={startIconSvg} className="text-essential-tertiary ml-3 mr-1" />
          </div>
        )}
        {textSymbol && align === InputAlign.LEFT && (
          <span data-test-id={`${id}-text-symbol`} className="flex items-center ml-3">
            {textSymbol}
          </span>
        )}
        <input
          className={getInputStyle({
            size,
            theme,
            align,
          })}
          data-test-id={dataTestId}
          id={id}
          type={type}
          name={name}
          aria-label={name ?? label ?? id}
          value={value}
          disabled={isDisabled}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          onReset={onChange}
          {...htmlInputProps}
        />
        {textSymbol && align === InputAlign.RIGHT && (
          <span data-test-id={`${id}-text-symbol`} className="flex items-center mr-3 body-base text-secondary">
            {textSymbol}
          </span>
        )}
        <span
          aria-disabled={isDisabled}
          className={cx({
            'relative flex align-items-center mr-2 ml-1 space-x-3 bg-transparent h-full':
              !!endComponent || !!endIconSvg,
          })}
        >
          {renderEndComponent?.({ isDisabled }) ?? endComponent}
          {endIconSvg && <SVG src={endIconSvg} />}
        </span>
      </div>
      {infoMessage && (
        <span data-test-id={`${dataTestId}-info-message`} className="text-essential-tertiary block text-sm pt-2 pl-0.5">
          &#9432; {infoMessage}
        </span>
      )}
      {errorMessage && (
        <span data-test-id={`${dataTestId}-error-message`} className="text-danger block text-sm pt-2 pl-0.5">
          &#9432; {errorMessage}
        </span>
      )}
    </div>
  );
};

/* eslint-enable react/jsx-props-no-spreading */
