import { Color } from 'lib/Button';

export enum DropdownMenuPosition {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export interface DropdownMenuOption {
  id: string;
  label: string;
  onClick: () => void;
}

export interface DropdownMenuProps {
  dropdownMenuOptions: DropdownMenuOption[];
  dropdownMenuPosition?: DropdownMenuPosition;
  icon?: string;
  label: string;
  color?: Color;
}

export const dropdownMenuPositions: Record<DropdownMenuPosition, string> = {
  [DropdownMenuPosition.LEFT]: 'left-0',
  [DropdownMenuPosition.RIGHT]: 'right-0',
};
