import SVG from 'react-inlinesvg';
import cx from 'classnames';
import ButtonProps, { Color, Size, TextSize } from './Button.types';

const Button = ({
  ariaLabel,
  classNames,
  color,
  dataTestId,
  label,
  onClick,
  isDisabled,
  size,
  svg,
  svgClassnames,
  ddActionName,
  textSize,
}: ButtonProps): React.ReactElement => {
  return (
    <button
      data-test-id={dataTestId}
      type="button"
      onClick={onClick}
      disabled={isDisabled}
      aria-label={ariaLabel}
      data-dd-action-name={ddActionName ?? label}
      className={cx(
        'flex items-center gap-1 rounded-md leading-none align-middle disabled:cursor-not-allowed disabled:bg-neutral-300 disabled:text-neutral-400 justify-center',
        color,
        size,
        classNames,
        textSize,
      )}
    >
      {svg ? <SVG src={svg} className={cx('fill-current inline-block w-4 h-4', svgClassnames)} /> : null}
      {label && <span className="whitespace-nowrap text-ellipsis">{label}</span>}
    </button>
  );
};

Button.defaultProps = {
  dataTestId: '',
  svg: '',
  color: Color.PRIMARY,
  size: Size.MEDIUM,
  onClick: () => undefined,
  isDisabled: false,
  textSize: TextSize.MEDIUM,
};

export default Button;
