import { ChangeEventHandler, FocusEventHandler, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { formatNumber, getSeparators } from 'modules/I18N';
import { formatUserInput } from 'utils/formatters';
import { MAX_SAFE_INTEGER_ALLOWED_IN_BACKEND } from 'modules/api/constants';
import { InputSize, InputTheme, InputType } from 'components/common/types/Input.types';
import { isValidNumber } from 'utils/validationRules';
import { RootState } from 'store';
import { BaseInput } from 'lib/BaseInput';
import { InputNumberProps } from './InputNumber.types';

const InputNumber: React.FC<InputNumberProps> = ({
  value,
  id,
  dataTestId,
  name,
  size = InputSize.MEDIUM,
  errorMessage = '',
  icon = '',
  textSymbol = '',
  isDisabled = false,
  isRequired = false,
  label = '',
  min = '0',
  max = MAX_SAFE_INTEGER_ALLOWED_IN_BACKEND,
  maximumFractionDigits = 2,
  placeholder = '',
  theme = InputTheme.LIGHT,
  isEmpty = false,
  onChange,
  onBlur,
  align,
}) => {
  const [inputValue, setInputValue] = useState<string | undefined>(undefined);
  const { localeCode } = useSelector((state: RootState) => state.publisher.configuration);

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = (event): void => {
    const userInput = event.target.value;

    if (isEmpty && !userInput) {
      setInputValue(undefined);
      onChange?.(undefined);

      return;
    }

    if (isValidNumber(userInput, max, maximumFractionDigits, localeCode)) {
      const formattedUserInput = formatUserInput(userInput, localeCode);
      const { decimalSeparator } = getSeparators(localeCode);
      const lastCharacter = userInput[userInput.length - 1];
      const isUserInputZeroAfterSeparator = lastCharacter === '0' && userInput.includes(decimalSeparator);
      const queryValue =
        lastCharacter === decimalSeparator || isUserInputZeroAfterSeparator
          ? userInput
          : formatNumber({ value: formattedUserInput, localeCode });

      setInputValue(queryValue);
      onChange?.(formattedUserInput);
    }
  };

  const handleOnBlur: FocusEventHandler<HTMLInputElement> = () => {
    onBlur?.(inputValue);
  };

  useEffect(() => {
    if (isEmpty && value !== 0 && !value) {
      setInputValue(undefined);

      return;
    }

    setInputValue(formatNumber({ value: value as number, localeCode }));
  }, [value]);

  return (
    <BaseInput
      dataTestId={dataTestId}
      id={id}
      label={label}
      name={name}
      placeholder={placeholder}
      size={size}
      value={inputValue || ''}
      type={InputType.TEXT}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      theme={theme}
      startIconSvg={icon}
      isDisabled={isDisabled}
      isRequired={isRequired}
      errorMessage={errorMessage}
      infoMessage=""
      align={align}
      textSymbol={textSymbol}
      min={min}
      max={max}
    />
  );
};

export default InputNumber;
