import { useState } from 'react';
import SVG from 'react-inlinesvg';
import { RuntimeFilterOp } from '@thoughtspot/visual-embed-sdk';

import LiveboardVisuals from 'components/patterns/LiveboardVisuals';
import { thoughtspot } from 'config';
import emptyIcon from 'assets/icons/empty.svg';
import Filter from './Filter';
import { DealIdType } from './Filter/Filter.types';

const DealInfo: React.FC = () => {
  const [selectedDealId, setSelectedDealId] = useState<DealIdType | null>(null);

  const onFilter = (value: DealIdType): void => {
    setSelectedDealId(value);
  };

  const filterByDealId = [
    {
      columnName: 'Deal ID',
      operator: RuntimeFilterOp.EQ,
      values: [selectedDealId?.dealId || ''],
    },
  ];

  return (
    <>
      <Filter onChange={onFilter} />
      {selectedDealId ? (
        <LiveboardVisuals liveboardId={thoughtspot.dealDetailsId} filterByOptions={filterByDealId} />
      ) : (
        <div className="flex flex-col justify-center items-center min-h-screen bg-neutral-50 border rounded-lg border-neutral-100-opacity-10 text-neutral-950-opacity-60">
          <SVG src={emptyIcon} />
          <p className="mt-11 mb-4 header-lg">No results found</p>
          <p className="mb-4 text-center body-base">Revise your query. Add a valid Deal ID to see data</p>
        </div>
      )}
    </>
  );
};

export default DealInfo;
