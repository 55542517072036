import { Preloader } from 'components/patterns/Loader';
import Button, { Color, Size } from 'lib/Button';
import downloadSvg from 'assets/icons/download.svg';
import { AllocationReportProps, ReportType } from './AllocationReport.types';
import { AllocationReportModal } from './AllocationReportModal/AllocationReportModal';
import { DropdownDealLine } from './Filters/DropdownDealLine';
import { DropdownMetric } from './Filters/DropdownMetric';
import { useAllocationReport } from './useAllocationReport';
import { AllocationReportTable } from './Table/AllocationReportTable';
import { ReportTypeButtons } from './ReportTypeButtons/ReportTypeButtons';

const AllocationReport: React.FC<AllocationReportProps> = ({ defaultReportType = ReportType.FINAL, onClose }) => {
  const {
    activeReportType,
    allocationReportTableParams: { gridOptions, onGridReady, rowData },
    dealLines,
    handleChangeLine,
    handleChangeMetricType,
    handleFetchAllocationReport,
    isLoading,
    isSelectedLineWithNewProposal,
    selectedLine,
    selectedMetricType,
    handleExportReport,
  } = useAllocationReport({
    defaultReportType,
  });

  return (
    <AllocationReportModal onClose={onClose}>
      <div className="h-full flex flex-col overflow-hidden">
        <header className="flex items-end justify-between p-4 border-b border-neutral-950-opacity-10 mb-[8px]">
          <div className="flex gap-3">
            <DropdownDealLine dealLines={dealLines} handleChangeLine={handleChangeLine} selectedLine={selectedLine} />
            <DropdownMetric handleChangeMetricType={handleChangeMetricType} selectedMetricType={selectedMetricType} />
          </div>
          <Button
            svg={downloadSvg}
            label="Export report"
            size={Size.LARGE}
            color={Color.TRANSPARENT}
            onClick={handleExportReport}
            ddActionName="Allocation report / Export button"
          />
        </header>
        {isLoading ? (
          <Preloader isLoading />
        ) : (
          <>
            <ReportTypeButtons
              activeReportType={activeReportType}
              handleFetchAllocationReport={handleFetchAllocationReport}
              isSelectedLineWithNewProposal={isSelectedLineWithNewProposal}
              selectedLine={selectedLine}
            />
            <AllocationReportTable gridOptions={gridOptions} onGridReady={onGridReady} rowData={rowData} />
          </>
        )}
      </div>
    </AllocationReportModal>
  );
};

export default AllocationReport;
