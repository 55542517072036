import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import xlsx from 'json-as-xlsx';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { ColGroupDef, ColumnGroupShowType, GridOptions, GridReadyEvent, ValueFormatterParams } from 'ag-grid-community';
import { getFinalAllocationReport, getProposalAllocationReport } from 'modules/api/allocationReport';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { TableHeader } from 'components/common/TableHeader';
import { HeaderBackgroundColor, TextColor } from 'components/common/TableHeader/TableHeader.types';
import { Store } from '../types/Store.types';
import {
  AllocationReportData,
  Frame,
  MetricOption,
  ReportType,
  SelectedLine,
  TotalByWeek,
} from './AllocationReport.types';
import { HOURS, defaultMetricOption } from './consts';
import { HeaderGroupComponent } from './Table/CustomComponents/HeaderGroupComponent';
import { ReportCreationDateChip } from './Table/CustomComponents/ReportCreationDateChip';
import { generateColumnHeaders, togglePinnedColumnOnColumnGroupOpen } from './Table/utils';
import { generateFileName, valueFormatters } from './utils';

export type ChangeLineHandler = (fieldName: string, selectedLine: SelectedLine) => void;
export type ChangeMetricTypeHandler = (selectedMetricType: MetricOption) => void;

export interface AllocationReportTableParams {
  gridOptions: GridOptions;
  onGridReady: (event: GridReadyEvent<Frame[]>) => void;
  rowData: Frame[];
}

export type UseAllocationReport = ({ defaultReportType }: { defaultReportType: ReportType }) => {
  activeReportType: ReportType;
  allocationReportData?: AllocationReportData;
  allocationReportTableParams: AllocationReportTableParams;
  dealLines: SelectedLine[];
  handleChangeLine: ChangeLineHandler;
  handleChangeMetricType: ChangeMetricTypeHandler;
  handleFetchAllocationReport: (reportType: ReportType) => void;
  isLoading: boolean;
  isSelectedLineWithNewProposal: boolean;
  selectedLine: SelectedLine;
  selectedMetricType: MetricOption;
  handleExportReport: () => void;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const useAllocationReport: UseAllocationReport = ({ defaultReportType }) => {
  const cancelFunctions = useCancelRequest();

  const localeCode = useSelector((state: Store) => state.publisher.configuration.localeCode);
  const campaignType = useSelector((state: Store) => state.dealManagement.campaignType);
  const dealId = useSelector((state: Store) => state.dealManagement.commonDeal.dealId);
  const currentLineId = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.lineId);
  const currentLineName = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.name);
  const currentLineStatus = useSelector(
    (state: Store) => state.dealManagement.commonDeal.currentLine.bookingStatusCode,
  );
  const proposalCampaignId = useSelector((state: Store) => state.dealManagement.temporaryDealId);
  const backupLines = useSelector((state: Store) => state.dealManagement.backupFormData.lines);
  const { timezone } = useSelector((state: Store) => state.publisher.configuration);

  const defaultCurrentLine: SelectedLine = {
    lineDefaultReportType: ReportType.PROPOSAL,
    lineId: currentLineId,
    lineName: currentLineName,
    lineStatus: currentLineStatus,
  };

  const dealLines: SelectedLine[] = useMemo(() => {
    if (!backupLines.length) {
      return [defaultCurrentLine];
    }

    const lines: SelectedLine[] = backupLines.map(({ name, lineId, bookingStatusCode }) => {
      return {
        lineDefaultReportType:
          lineId === currentLineId && proposalCampaignId && defaultReportType === ReportType.PROPOSAL
            ? ReportType.PROPOSAL
            : ReportType.FINAL,
        lineId,
        lineName: name,
        lineStatus: bookingStatusCode,
      };
    });

    if (!currentLineId) {
      lines.push(defaultCurrentLine);
    }

    return lines;
  }, [backupLines, currentLineId, proposalCampaignId]);

  const defaultSelectedLine: SelectedLine =
    dealLines.find(({ lineId }) => lineId === currentLineId) || defaultCurrentLine;

  const [isLoading, setIsLoading] = useState(false);
  const [allocationReportData, setAllocationReportData] = useState<AllocationReportData>();
  const [selectedLine, setSelectedLine] = useState<SelectedLine>(defaultSelectedLine);
  const [selectedMetricType, setSelectedMetricType] = useState(defaultMetricOption);
  const [activeReportType, setActiveReportType] = useState(selectedLine.lineDefaultReportType);
  const [rowData, setRowData] = useState<Frame[]>([]);
  const [totalAllocations, setTotalAllocations] = useState<TotalByWeek[]>([]);
  const [startOfLoadingTimeInMs, setStartOfLoadingTimeInMs] = useState<number>();

  const isSelectedLineWithNewProposal = selectedLine.lineId === currentLineId && !!proposalCampaignId;

  const fetchAllocationReport = async (reportType: ReportType): Promise<void> => {
    try {
      setIsLoading(true);
      setStartOfLoadingTimeInMs(performance.now());

      let allocationReportResponse;

      if (reportType === ReportType.PROPOSAL) {
        allocationReportResponse = await getProposalAllocationReport(
          proposalCampaignId,
          selectedMetricType.code,
          cancelFunctions,
        );
      } else {
        allocationReportResponse = await getFinalAllocationReport(
          dealId,
          selectedLine.lineId,
          selectedMetricType.code,
          cancelFunctions,
        );
      }

      setAllocationReportData(allocationReportResponse);
      setRowData(allocationReportResponse.frames);
      setActiveReportType(reportType);

      if (allocationReportResponse?.totalAllocations?.length > 0) {
        setTotalAllocations(allocationReportResponse.totalAllocations);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Allocation Report fetch failed:', error);
      datadogLogs.logger.error('Allocation Report fetch failed', { tradingType: campaignType, error });
      setAllocationReportData(undefined);
      setRowData([]);
    } finally {
      setIsLoading(false);
    }
  };

  const columnHeaders = useMemo(() => {
    if (allocationReportData?.startDate && allocationReportData.endDate) {
      return generateColumnHeaders(
        new Date(allocationReportData.startDate),
        new Date(allocationReportData.endDate),
        localeCode,
      );
    }

    return null;
  }, [allocationReportData?.startDate, allocationReportData?.endDate]);

  const onGridReady = useCallback<AllocationReportTableParams['onGridReady']>(
    (onGridReadyEvent) => {
      if (!allocationReportData || !columnHeaders) {
        datadogLogs.logger.error('Allocation Report load failed', { tradingType: campaignType });
        onGridReadyEvent.api.showNoRowsOverlay();

        return;
      }

      const columnDefinitions: ColGroupDef[] = [
        {
          headerGroupComponent: () =>
            !!allocationReportData.metaData &&
            ReportCreationDateChip({
              creationDateTime: allocationReportData.metaData.report.creationDateTime,
              timezone,
            }),
          groupId: 'timestamp',
          children: [
            {
              cellClass: 'frame-cell',
              colId: 'frame-id',
              field: 'frameId',
              headerComponent: TableHeader,
              headerComponentParams: {
                backgroundColor: HeaderBackgroundColor.WHITE,
                label: 'Frame ID',
                sortable: true,
                textColor: TextColor.SECONDARY,
              },
              width: 170,
              pinned: 'left',
              sortable: true,
            },
          ],
        },
        {
          groupId: 'total-duration',
          headerGroupComponent: HeaderGroupComponent,
          headerGroupComponentParams: {
            backgroundColor: HeaderBackgroundColor.WHITE,
            label: columnHeaders.label,
            hideDuplicateWhenPinned: true,
            headerLevel: 'Total line duration',
          },
          openByDefault: false,
          children: [
            {
              cellClass: 'data-cell',
              colId: 'frame-total',
              field: 'total',
              headerComponent: TableHeader,
              headerComponentParams: {
                backgroundColor: HeaderBackgroundColor.WHITE,
                label: 'Total',
                textColor: TextColor.SECONDARY,
              },
              openByDefault: true,
              width: 170,
              valueFormatter: (params) => valueFormatters[selectedMetricType.code](params.data.total),
            },
            ...columnHeaders.weeks.map((week) => ({
              columnGroupShow: 'open' as ColumnGroupShowType,
              headerGroupComponent: HeaderGroupComponent,
              headerGroupComponentParams: {
                label: week.label,
                headerLevel: 'Week',
              },
              children: [
                {
                  cellClass: 'data-cell',
                  columnGroupShow: 'closed',
                  field: `allocations.${week.allocationWeek}.total`,
                  headerName: '',
                  valueFormatter: (params: ValueFormatterParams) =>
                    valueFormatters[selectedMetricType.code](params.data.allocations?.[week.allocationWeek].total),
                },
                {
                  cellClass: 'data-cell',
                  columnGroupShow: 'open',
                  field: `allocations.${week.allocationWeek}.total`,
                  headerGroupComponent: TableHeader,
                  headerGroupComponentParams: {
                    backgroundColor: HeaderBackgroundColor.SECONDARY,
                    label: 'Total',
                  },
                  valueFormatter: (params: ValueFormatterParams) =>
                    valueFormatters[selectedMetricType.code](params.data.allocations[week.allocationWeek].total),
                  children: [
                    {
                      cellClass: 'data-cell',
                      columnGroupShow: 'open',
                      headerName: '',
                      field: `allocations.${week.allocationWeek}.total`,
                      valueFormatter: (params: ValueFormatterParams) =>
                        valueFormatters[selectedMetricType.code](params.data.allocations?.[week.allocationWeek].total),
                    },
                  ],
                },
                ...week.days.map((day, dayIndex) => ({
                  columnGroupShow: 'open',
                  field: `allocations.${week.allocationWeek}.days.${dayIndex}.total`,
                  headerGroupComponent: HeaderGroupComponent,
                  headerGroupComponentParams: {
                    label: day.label,
                    headerLevel: 'Day',
                  },
                  valueFormatter: (params: ValueFormatterParams) =>
                    valueFormatters[selectedMetricType.code](
                      params.data.allocations[week.allocationWeek].days[dayIndex].total,
                    ),
                  children: [
                    {
                      cellClass: 'data-cell',
                      columnGroupShow: 'closed',
                      field: `allocations.${week.allocationWeek}.days.${dayIndex}.total`,
                      headerName: '',
                      valueFormatter: (params: ValueFormatterParams) =>
                        valueFormatters[selectedMetricType.code](
                          params.data.allocations?.[week.allocationWeek].days[dayIndex].total,
                        ),
                    },
                    {
                      cellClass: 'data-cell',
                      columnGroupShow: 'open',
                      field: `allocations.${week.allocationWeek}.days.${dayIndex}.total`,
                      headerComponent: TableHeader,
                      headerComponentParams: {
                        backgroundColor: HeaderBackgroundColor.SECONDARY,
                        label: 'Total',
                      },
                      // eslint-disable-next-line sonarjs/no-identical-functions
                      valueFormatter: (params: ValueFormatterParams) =>
                        valueFormatters[selectedMetricType.code](
                          params.data.allocations?.[week.allocationWeek].days[dayIndex].total,
                        ),
                    },
                    ...HOURS.map((hour, hourIndex) => ({
                      cellClass: 'data-cell',
                      columnGroupShow: 'open',
                      field: `allocations.${week.allocationWeek}.days.${dayIndex}.hours.${hourIndex}.metricValue`,
                      headerComponent: TableHeader,
                      headerComponentParams: {
                        backgroundColor: HeaderBackgroundColor.PRIMARY,
                        label: hour.label,
                        headerLevel: 'Hour',
                      },
                      valueFormatter: (params: ValueFormatterParams) =>
                        valueFormatters[selectedMetricType.code](
                          params.data.allocations?.[week.allocationWeek].days[dayIndex].hours[hourIndex].metricValue,
                        ),
                    })),
                  ],
                })),
              ],
            })),
          ],
        },
      ];

      onGridReadyEvent.api.setColumnDefs(columnDefinitions);

      const loadingEndTime = performance.now();

      datadogLogs.logger.info('Allocation Report loaded', {
        tradingType: campaignType,
        allocationReportLoadTimeInMs: Math.trunc(loadingEndTime - startOfLoadingTimeInMs!),
        networkSpeedInMbps: navigator.connection?.downlink || null, // Chromium's hard limit is 10 Mbps,
      });
    },
    [allocationReportData],
  );

  const gridOptions: GridOptions = {
    onGridReady,
    onColumnGroupOpened: (params) =>
      togglePinnedColumnOnColumnGroupOpen(params, 'total-duration', 'frame-total', 'right'),
    suppressAsyncEvents: true,
    defaultColDef: {
      width: 120,
      resizable: true,
      suppressMenu: true,
    },
    suppressMovableColumns: true,
    alwaysShowHorizontalScroll: true,
    pinnedBottomRowData:
      totalAllocations.length > 0
        ? [
            {
              allocations: totalAllocations,
              frameId: 'Total',
              total: allocationReportData?.total,
            },
          ]
        : undefined,
  };

  const handleChangeLine: ChangeLineHandler = (_, line): void => {
    setSelectedLine(line);
    setActiveReportType(line.lineDefaultReportType);
  };

  const handleChangeMetricType: ChangeMetricTypeHandler = (metricType): void => {
    datadogRum.addAction(`click on Allocation report / ${metricType.name} metric type`);
    setSelectedMetricType(metricType);
  };

  useEffect(() => {
    if (selectedLine && selectedMetricType && !isLoading) fetchAllocationReport(activeReportType);
  }, [selectedLine, selectedMetricType]);

  const handleExportReport = (): void => {
    const isSelectedLineWithProposalReport = activeReportType === ReportType.PROPOSAL;

    const sheetName = isSelectedLineWithProposalReport ? 'Proposal Report' : 'Allocation Report';
    const fileName = generateFileName({
      isSelectedLineWithProposalReport,
      selectedMetricType,
      selectedLine,
      timezone,
    });

    if (allocationReportData) {
      xlsx(
        [
          {
            sheet: sheetName,
            columns: [
              {
                label: 'Frame ID',
                value: 'frameId',
              },
              {
                label: 'Week number (Local Time)',
                value: 'weekNumber',
              },
              {
                label: 'Week period (Local Time)',
                value: 'weekPeriod',
              },
              {
                label: 'Day (Local Time)',
                value: 'date',
              },
              {
                label: 'Hour (Local Time)',
                value: 'hour',
              },
              {
                label: 'Datetime (Local Time)',
                value: 'dateTime',
              },
              {
                label: selectedMetricType.name,
                value: selectedMetricType.code,
              },
            ],
            content: allocationReportData.frames.flatMap((frame) =>
              frame.allocations.flatMap((allocation) =>
                allocation.days.flatMap((date) =>
                  date.hours.map((hour) => {
                    const formattedAllocationHour =
                      hour.allocationHour < 10 ? `0${hour.allocationHour}` : hour.allocationHour;
                    const dateTime = `${date.date} ${formattedAllocationHour}:00:00.000 ${timezone}`;
                    const weekPeriod = `${allocation.days[0].date} - ${allocation.days.at(-1)!.date}`;

                    return {
                      frameId: frame.frameId,
                      weekNumber: allocation.allocationWeek,
                      weekPeriod,
                      date: date.date,
                      hour: hour.allocationHour,
                      dateTime,
                      [selectedMetricType.code]: hour.metricValue,
                    };
                  }),
                ),
              ),
            ),
          },
        ],
        { fileName },
      );
    }
  };

  return {
    activeReportType,
    allocationReportTableParams: {
      gridOptions,
      onGridReady,
      rowData,
    },
    allocationReportData,
    dealLines,
    handleChangeLine,
    handleChangeMetricType,
    handleFetchAllocationReport: fetchAllocationReport,
    isLoading,
    isSelectedLineWithNewProposal,
    selectedLine,
    selectedMetricType,
    handleExportReport,
  };
};
