import { NavLink } from 'react-router-dom';

const PacingHeader: React.FC = () => {
  const getActiveStyles = ({ isActive }: { isActive: boolean }): string => {
    const defaultStyles = 'block p-2 w-52';

    return isActive
      ? `bg-primary text-neutral-100 ${defaultStyles}`
      : `bg-neutral-50 text-neutral-950-opacity-60 ${defaultStyles}`;
  };

  return (
    <ul className="pt-8 px-14 space-x-1.5 sub-header-base flex text-center">
      <li className="rounded-l-md overflow-hidden">
        <NavLink to="overview" className={getActiveStyles}>
          Overview
        </NavLink>
      </li>
      <li className="rounded-r-md overflow-hidden">
        <NavLink to="deal-info" className={getActiveStyles}>
          Deal info
        </NavLink>
      </li>
    </ul>
  );
};

export default PacingHeader;
