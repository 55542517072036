export interface ChipProps {
  label: string;
  subLabel?: string;
  color?: Color;
  size?: Size;
  icon?: string;
  isRemovable?: boolean;
  onRemove?(label: string): void;
  isDisabled?: boolean;
  dataTestId?: string;
}

export type ChipStyles = {
  wrapperStyles: string;
  iconStyles: string;
  labelStyles: string;
  subLabelStyles: string;
  removeButtonStyles: string;
};

export enum Color {
  DEFAULT,
  PRIMARY,
  PURPLE,
  PINK_RED,
  YELLOW,
  LIME,
  PINK,
  ORANGE,
  SKY_BLUE,
  RED,
  GREEN,
  DISABLED,
}

export enum Size {
  SMALL,
  MEDIUM,
  LARGE,
}
