import { useCallback, useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';

import { getDealsIdList } from 'modules/api/deals';
import withCancelRequest from 'components/hocs/withCancelRequest';

import AsyncSelect from 'components/patterns/AsyncSelect';

import Card from 'components/patterns/Card';
import { DealType } from 'components/common/types/Deal.types';
import filterIcon from 'assets/icons/filter.svg';
import { getDaysDiff } from 'utils/dateFormatUtil';
import Option from './Option';
import {
  PageParamsByStatus,
  DealIdType,
  FilteredDealData,
  FilterProps,
  PagesDataOptions,
  DealIdsDataByStatusCode,
  PagesStatusCode,
  PageParams,
} from './Filter.types';
import { DEALS_LIMIT, DEFAULT_PAGES_STATE_BY_STATUS_CODE, DAYS_AFTER_ENDED } from './utils';

const Filter: React.FC<FilterProps> = ({ onChange, cancelFunctions }) => {
  const [selectedValue, setSelectedValue] = useState<DealIdType | null>(null);
  const [pageParams, setPageParams] = useState<PageParamsByStatus>({
    [PagesStatusCode.LIVE]: DEFAULT_PAGES_STATE_BY_STATUS_CODE,
    [PagesStatusCode.ENDED]: DEFAULT_PAGES_STATE_BY_STATUS_CODE,
  });
  const [dealIds, setDealIds] = useState<DealIdsDataByStatusCode>({
    [PagesStatusCode.LIVE]: [],
    [PagesStatusCode.ENDED]: [],
  });

  const getFilteredDealsData = (deals = []): FilteredDealData[] => {
    return deals.reduce((acc: FilteredDealData[], currentValue): FilteredDealData[] => {
      const { dealId, dealName, bookingStatusCode: pagesStatusCode, endDate } = currentValue;

      if (
        pagesStatusCode === PagesStatusCode.LIVE ||
        (pagesStatusCode === PagesStatusCode.ENDED && getDaysDiff(new Date(endDate), new Date()) <= DAYS_AFTER_ENDED)
      ) {
        acc.push({
          dealName: `${dealId} : ${dealName}`,
          dealId,
          pagesStatusCode,
        });
      }

      return acc;
    }, []);
  };

  const handleChangePagesState = (pagesStatusCode: PagesStatusCode, pagesData: Partial<PageParams>): void => {
    setPageParams((prevState) => ({
      ...prevState,
      [pagesStatusCode]: { ...prevState[pagesStatusCode], ...pagesData },
    }));
  };

  const handleChangeDealIdsState = (pagesStatusCode: PagesStatusCode, dealIdTypes: DealIdType[]): void => {
    setDealIds((prevState) => ({ ...prevState, [pagesStatusCode]: dealIdTypes }));
  };

  const loadDealsData = async (
    pagesStatusCode = PagesStatusCode.LIVE,
    page = 0,
    currentData: DealIdType[] = [],
  ): Promise<void> => {
    try {
      const { content, isLastPage } = await getDealsIdList(cancelFunctions, {
        page,
        bookingStatusCode: PagesStatusCode[pagesStatusCode],
        size: DEALS_LIMIT,
        dealType: DealType.GUARANTEED,
      });

      handleChangePagesState(pagesStatusCode, { [PagesDataOptions.IS_LAST]: isLastPage });
      handleChangeDealIdsState(pagesStatusCode, currentData.concat(getFilteredDealsData(content)));

      if (pagesStatusCode === PagesStatusCode.LIVE && !currentData.length && content.length < DEALS_LIMIT) {
        await loadDealsData(PagesStatusCode.ENDED);
        handleChangePagesState(PagesStatusCode.ENDED, { [PagesDataOptions.CURRENT_PAGE]: 0 });
      }
    } catch (error) {
      // eslint-disable-next-line no-empty
    }
  };

  useEffect(() => {
    const handleInitialData = async (): Promise<void> => {
      await loadDealsData(PagesStatusCode.LIVE, 0, []);
    };

    handleInitialData();
  }, []);

  const handleOnScrolledDown = useCallback(async (): Promise<void> => {
    const {
      [PagesStatusCode.LIVE]: { isLast: isLastLivePage, currentPage: currentLiveDealsPage },
      [PagesStatusCode.ENDED]: { isLast: isLastEndedPage, currentPage: currentEndedDealsPage },
    } = pageParams;
    const { [PagesStatusCode.LIVE]: listOfLiveDealIds, [PagesStatusCode.ENDED]: listOfEndedDealIds } = dealIds;

    if (isLastLivePage && isLastEndedPage) return;

    if (!isLastLivePage) {
      const newCurrentLivePage = currentLiveDealsPage + 1;

      await loadDealsData(PagesStatusCode.LIVE, newCurrentLivePage, listOfLiveDealIds);
      handleChangePagesState(PagesStatusCode.LIVE, { [PagesDataOptions.CURRENT_PAGE]: newCurrentLivePage });
    } else {
      const newCurrentEndedPage = currentEndedDealsPage + 1;

      await loadDealsData(PagesStatusCode.ENDED, newCurrentEndedPage, listOfEndedDealIds);
      handleChangePagesState(PagesStatusCode.ENDED, { [PagesDataOptions.CURRENT_PAGE]: newCurrentEndedPage });
    }
  }, [pageParams, dealIds]);

  const onLookupData = async (_dropdownName: string, qry: string): Promise<DealIdType[]> => {
    const { [PagesStatusCode.LIVE]: listOfLiveDealIds, [PagesStatusCode.ENDED]: listOfEndedDealIds } = dealIds;

    return listOfLiveDealIds.concat(listOfEndedDealIds).filter(({ dealName }) => dealName.includes(qry));
  };

  const onLookUpDealIds = (_: string, value: DealIdType): void => {
    setSelectedValue(value);
    onChange(value);
  };

  const groupedOptions = [
    {
      label: 'LIVE DEALS',
      options: dealIds[PagesStatusCode.LIVE],
    },
    {
      label: 'ENDED DEALS',
      options: dealIds[PagesStatusCode.ENDED],
    },
  ];

  return (
    <Card>
      <div className="flex items-start space-x-4">
        <div className="mr-6 ml-3 my-1.5">
          <SVG src={filterIcon} className="text-neutral-400" />
        </div>
        <div className="w-88">
          <AsyncSelect
            dataTestId="deal-filter"
            name="dealId"
            textKey="dealName"
            valueKey="dealId"
            loadData={onLookupData}
            defaultOptions={groupedOptions}
            onSelect={onLookUpDealIds}
            onMenuScrollToBottom={handleOnScrolledDown}
            placeholder="Deal ID"
            selectedValue={selectedValue || null}
            customComponents={{ Option }}
            debounceTime={0}
          />
        </div>
      </div>
    </Card>
  );
};
export default withCancelRequest(Filter);
