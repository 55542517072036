import { Routes, Route, Navigate, Outlet } from 'react-router-dom';

import { useCancelRequest } from 'customHooks/useCancelRequest';
import { initialiseThoughtspot } from 'utils/thoughtspotInit';
import PrivateRoute from 'components/common/Auth/Private';
import SingleContent from 'components/common/layout/SingleContent';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { ChildRoute } from 'components/common/types/Route.types';
import PacingHeader from './PacingHeader';
import Overview from './Overview';
import DealInfo from './DealInfo';

const Pacing: React.FC = () => {
  const { localeCode } = useSelector((state: RootState) => state.publisher.configuration);
  const cancelFunctions = useCancelRequest();
  const childRoutes: ChildRoute[] = [
    {
      id: 1,
      path: 'overview',
      main: Overview,
      pageAccessPermission: undefined,
    },
    {
      id: 2,
      path: 'deal-info',
      main: DealInfo,
      pageAccessPermission: undefined,
    },
    {
      id: 3,
      path: '*',
      main: () => <Navigate to="overview" />,
      pageAccessPermission: undefined,
    },
  ];

  initialiseThoughtspot(cancelFunctions, localeCode);

  return (
    <>
      <PacingHeader />
      <Routes>
        <Route element={<SingleContent content={<Outlet />} />}>
          {childRoutes.map((route) => (
            <Route
              key={route.id}
              path={route.path}
              element={<PrivateRoute component={route.main} pageAccessPermission={route.pageAccessPermission} />}
            />
          ))}
        </Route>
      </Routes>
    </>
  );
};

export default Pacing;
