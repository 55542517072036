import Session from 'modules/Session';
import * as lookups from 'modules/api/lookups';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { CodeNameModel } from 'components/common/types';
import AsyncSelect from 'components/patterns/AsyncSelect';
import { FilterMultiDealIdProps } from './FilterDealMultiId.types';

const FilterDealMultiId: React.FC<FilterMultiDealIdProps> = ({ changeFilters, dealIds }) => {
  const cancelFunctions = useCancelRequest();

  const onLoadLookupData = async (dropdownName: string, query: string): Promise<[]> => {
    try {
      if (query.length < 2) {
        return [];
      }

      return await lookups.getCreativeAdminDealIdLookupData(cancelFunctions, Session.getEnvironmentId(), query);
    } catch (error) {
      return [];
    }
  };

  return (
    <AsyncSelect
      dataTestId="deal-filter"
      name="dealIds"
      loadData={onLoadLookupData}
      selectedValue={dealIds}
      placeholder="Deal ID"
      onSelect={(_: string, value: CodeNameModel[]) =>
        changeFilters({
          dealIds: value,
        })
      }
      multiple
    />
  );
};

export default FilterDealMultiId;
